import { Injectable } from '@angular/core';
import { DateTime, Settings } from 'luxon';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';


export enum MeterType {
    drac = 'drac',
    drac_flowmeter = 'drac_flowmeter',
    drac_mid = 'drac_mid',
    v1 = 'v1',
    v2 = 'v2',
    sense = 'sense',
    thirdparty_maddalena = '3p_maddalena',
    thirdparty_hac = '3p_hac',
    demo = 'demo'
}

export enum MeterFeature {
    conductivity = 'conductivity',
    temperature = 'temperature',
    hourly_signal = 'hourly_signal',
    raw_signal = 'raw_signal',
    labelling = 'labelling',
    period_alerts = 'period_alerts',
    smart_alerts = 'smart_alerts',
    threshold_alerts = 'threshold_alerts',
    timeseries_db = 'timeseries_db',
    flow = 'flow'


}

export class Meter implements Model {
    constructor(
        public device_uuid: string,
        public first_seen: DateTime,
        public last_seen: DateTime,
        public label: string,
        public serial: string,
        public last_update: DateTime,
        public place_id: number,
        public categories: string[],
        public features: MeterFeature[],
        public type: MeterType,
        public type_request: string,
        public charge_level: number
    ) { }

    public get universalLabel(): string {
        if (this.label && this.label.trim() !== '') return this.label;
        if (this.serial && this.serial.trim() !== '') return this.serial;
        return this.device_uuid;
    }

    private static _typeToLabel(type: string) {
        switch (type) {
            case MeterType.drac:
                return $localize`drac`;
            case MeterType.drac_flowmeter:
                return $localize`drac flowmeter`;
            case MeterType.drac_mid:
                return $localize`drac MID`;
            case MeterType.v1:
                return $localize`v1 meter`;
            case MeterType.v2:
                return $localize`v2 meter`;
            case MeterType.sense:
                return $localize`HYDRAO Sense`;
            case MeterType.thirdparty_maddalena:
                return $localize`Maddalena`;
            case MeterType.thirdparty_hac:
                return $localize`HAC`;
            case MeterType.demo:
                return $localize`Demo meter`;
            default:
                return $localize`unknown`;
        }
    }

    public static featureToLabel(feature: MeterFeature) {
        switch (feature) {
            case MeterFeature.conductivity:
                return $localize`conductivity`;
            case MeterFeature.hourly_signal:
                return $localize`hourly signal`;
            case MeterFeature.labelling:
                return $localize`detailed use`;
            case MeterFeature.period_alerts:
                return $localize`period alerts`;
            case MeterFeature.raw_signal:
                return $localize`raw signal`;
            case MeterFeature.smart_alerts:
                return $localize`smart alerts`;
            case MeterFeature.temperature:
                return $localize`temperature`;
            case MeterFeature.threshold_alerts:
                return $localize`threshold alerts`;
            case MeterFeature.flow:
                return $localize`flow`;
            default:
                return $localize`unknown`;
        }
    }

    public get typeLabel(): string {
        return Meter._typeToLabel(this.type);
    }

    public get typeRequestLabel(): string {
        return Meter._typeToLabel(this.type_request);
    }

    public get typeRequestPending(): boolean {
        return this.type_request && this.type !== this.type_request
    }

    public get isDrac(): boolean {
        return this.type == MeterType.drac || this.type == MeterType.drac_flowmeter || this.type == MeterType.drac_mid;
    }

    public get isDracMid(): boolean {
        return this.type == MeterType.drac_mid;
    }

    public get isSense(): boolean {
        return this.type == MeterType.sense;
    }

    public get canViewHourlySignal(): boolean {
        return this.features && this.features.indexOf(MeterFeature.hourly_signal) >= 0;
    }

    public get canViewRawSignal(): boolean {
        return this.features && this.features.indexOf(MeterFeature.raw_signal) >= 0;
    }

    public get canViewMeterUsage(): boolean {
        return this.features && this.features.indexOf(MeterFeature.labelling) >= 0;
    }

    public get canViewTemperature(): boolean {
        return this.features && this.features.indexOf(MeterFeature.temperature) >= 0;
    }

    public get canViewConductivity(): boolean {
        return this.features && this.features.indexOf(MeterFeature.conductivity) >= 0;
    }

    public get canUseTSDB(): boolean {
        return this.features && this.features.indexOf(MeterFeature.timeseries_db) >= 0;
    }

    public get canViewFlow(): boolean {
        return this.features && this.features.indexOf(MeterFeature.flow) >= 0;
    }


}



@Injectable({
    providedIn: 'root'
})
export class MeterAdapter implements Adapter<Meter> {

    adapt(item: any): Meter {
        return new Meter(
            item.device_uuid,



            item.first_seen ? DateTime.fromISO(item.first_seen, { zone: Settings.defaultZone }) : null,
            item.last_seen ? DateTime.fromISO(item.last_seen, { zone: Settings.defaultZone }) : null,
            item.label,
            item.serial,
            item.last_update ? DateTime.fromISO(item.last_update, { zone: Settings.defaultZone }) : null,
            item.place_id,
            item.categories,
            item.features,
            item.type,
            item.type_request,
            item.charge_level
        );
    }
}